:root {

  --proxeet-orange: #FF7E33;
  --proxeet-blue: #152F4E;
  --proxeet-gray: #F7F7F7 ;
  --proxeet-light-blue: #00477A;
  --proxeet-ll-blue: #95BBDB;
  --proxeet-white: #FFFFFF;
}

.px-bg-blue {
  background: var(--proxeet-blue) !important;
}

.px-bg-ll-blue {
  background: var(--proxeet-ll-blue) !important;
}

.proxeet-bg-ll-blue {
  background: var(--proxeet-ll-blue) !important;
}
.proxeet-bg-orange {
  background: var(--proxeet-orange) !important;
}
.px-bg-orange {
  background: var(--proxeet-orange) !important;
}

.proxeet-white {
  color: var(--proxeet-white) !important;
}

.px-white {
  color: var(--proxeet-white) !important;
}

.proxeet-orange {
  color: var(--proxeet-orange) !important;
}

.proxeet-blue {
  color: var(--proxeet-blue) !important;
}

.proxeet-gray {
  color: var(--proxeet-gray) !important;
}

.proxeet-light-blue {
  color: var(--proxeet-light-blue) !important;
}


html, body {

  overflow-x: hidden;

 
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*@import url('https://www.dafontfree.net/embed/ZG9ja2VyLW9uZSZkYXRhLzE2L2QvNzgwNDcvRG9ja2VyIE9uZS50dGY" rel="stylesheet" type="text/css');*/

/*@import url('fonts/dockerone.ttf');*/

.modal-lg {


  max-width: 630px !important;
}

.modal {

  border: 0 !important;
}

.modal-content {

  border: 0 !important;
}


@font-face {
  font-family: "DOCKER";
  src: url("fonts/DOCKERONE.woff2") format("truetype");

  font-weight: 400;
  font-style: normal;

}


@font-face {
  font-family: 'Proxeet';
  src: url('fonts/proxeet.ttf') format("truetype");
}


@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Italic.woff2') format("truetype");
}


.chip {

  font-family: 'Proxeet';
  font-style: normal;
  font-style: normal;

}


.ttt {

  font-family: 'DOCKER';
  /*font-style: normal;*/
  /*color: #152F4E;*/
  
}

.font-docker {
  font-family: 'DOCKER';
}

.open-sans {

  font-family: 'Open Sans';
  font-style: normal;

}

ol > li::marker {
  color: #00477A;
  /*content:counter(list-item) ")\2003";*/
  font-size: 20px;
}

.tw{

  color: #FFFFFF;
}

.tb{

  color: #00477A;
}

.ttt2{

  font-family: 'DOCKER';
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 52px;

  color: #152F4E;

  
}

.btn-circle {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  
  
  font-size: 16px;
  border: none;
}

h1 {
  font-family: 'DOCKER';
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 52px;

  color: #152F4E;
}

.proxeet-pc-header {
  font-family: 'DOCKER';
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3rem;

  color: #152F4E;
}

.fs-3 {

}

.proxeet-header {
  font-family: 'DOCKER';
  font-style: normal;
  
  font-size: 2.5rem;
  line-height: 2rem;

  color: #152F4E;
}

.proxeet-tab-header {
  font-family: 'DOCKER';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;

  color: #152F4E;
}

.proxeet-mob-header {
  font-family: 'DOCKER';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.6rem;

  color: #152F4E;
}

.f-docker {

  font-family: 'DOCKER';
  color: #152F4E;

}


h3, h4, h5 {
  font-family: 'DOCKER';
 
  color: #152F4E;
}


.oferta:hover{

  background-color: #F8F9FA;
} 

.orange {

  font-size: 22px;
  color: #FF7E33;

}



.bigImgClass {
 
  
  background-color: #FFFFFF;


}

.btn-proxeet {
  font-family: 'DOCKER' !important;
  font-style: normal;
  font-weight: 400;
  width: 14rem !important;
  height: 4rem !important;
  font-size: 1.3rem !important;
  color: #FFFFFF !important;
  background: #FF7E33 !important;
  border-radius: 30px !important;
  
}

.btn-proxeet-h-3 {
  font-family: 'DOCKER' !important;
  font-style: normal;
  font-weight: 400;
  width: 14rem !important;
  height: 3rem !important;
  font-size: 1.3rem !important;
  color: #FFFFFF !important;
  background: #FF7E33 !important;
  border-radius: 30px !important;

}

.btn-proxeet-w19-h4 {
  font-family: 'DOCKER' !important;
  font-style: normal;
  font-weight: 400;
  width: 19rem !important;
  height: 4rem !important;
  font-size: 1.3rem !important;
  color: #FFFFFF !important;
  background: #FF7E33 !important;
  border-radius: 30px !important;
  
}

.btn-proxeet-blue {
  font-family: 'DOCKER' !important;
  font-style: normal;
  font-weight: 400;
  width: 14rem !important;
  height: 4rem !important;
  font-size: 1.3rem !important;
  color: #FFFFFF !important;
  background: #152F4E !important;
  border-radius: 30px !important;
  
}
.btn-proxeet-blue-w19-h-4 {
  font-family: 'DOCKER' !important;
  font-style: normal;
  font-weight: 400;
  width: 19rem !important;
  height: 4rem !important;
  font-size: 1.3rem !important;
  color: #FFFFFF !important;
  background: #152F4E !important;
  border-radius: 30px !important;
  
}

.btn-px-mob {
  font-family: 'DOCKER' !important;
  font-style: normal;
  font-weight: 400;
  width: 10rem !important;
  height: 2rem !important;
  font-size: .8rem !important;
  color: #FFFFFF !important;
  background: #FF7E33 !important;
  border-radius: 30px !important;

}

.btn-proxeet-mob {
  font-family: 'DOCKER' !important;
  font-style: normal;
  font-weight: 400;
  width: 14rem !important;
  height: 3.5rem !important;
  font-size: 1.1rem !important;
  color: #FFFFFF !important;
  background: #FF7E33 !important;
  border-radius: 30px !important;
  
}
.btn-proxeet-mob-w17 {
  font-family: 'DOCKER' !important;
  font-style: normal;
  font-weight: 400;
  width: 17rem !important;
  height: 3.5rem !important;
  font-size: 1.1rem !important;
  color: #FFFFFF !important;
  background: #FF7E33 !important;
  border-radius: 30px !important;
  
}

.btn-proxeet-mob-blue {

  font-family: 'DOCKER' !important;
  font-style: normal;
  
  width: 12rem !important;
  height: 3.5rem !important;
  font-size: 1.1rem !important;
  color: #FFFFFF !important;
  background: #152F4E !important;
  border-radius: 30px !important;
 

}

.btn-proxeet-mob-blue-w17 {

  font-family: 'DOCKER' !important;
  font-style: normal;
  
  width: 17rem !important;
  height: 3.5rem !important;
  font-size: 1.1rem !important;
  color: #FFFFFF !important;
  background: #152F4E !important;
  border-radius: 30px !important;
 

}


.btn-amazon {

  font-family: DOCKER !important;
  font-style: normal !important;
  font-size: 1.5rem !important;
  width: 19rem !important;
  height: 4rem !important;
  background: #152F4E !important;
  background-position: center !important;
  border-radius: 30px !important;
  
  background-repeat: no-repeat !important;
  background-size: 10rem 3rem !important;
  border: 0 !important;

}

.btn-amazon-com {
  
  background-image: url("https://proxeet.com/img/marketplaces/buy-on-amazon-button.png") !important;
  background-repeat: no-repeat !important;
  
}

.btn-amazon-all {
  
  background-image: url("https://proxeet.com/img/marketplaces/buy-on-amazon-button-all.png") !important;
  background-repeat: no-repeat !important;
  background-size: 8rem 1.9rem !important;
  background-position: center !important;
  
}

.btn-amazon-de {
  
  background-image: url("https://proxeet.com/img/marketplaces/buy-on-amazon-button-de.png") !important;
  background-repeat: no-repeat !important;
  
}
.btn-amazon-uk {

  background-image: url("https://proxeet.com/img/marketplaces/buy-on-amazon-button-uk.png") !important;
  background-repeat: no-repeat !important;
  
}
.btn-ebay {

  background-image: url("https://proxeet.com/img/marketplaces/buyEbay.png") !important;
  background-repeat: no-repeat !important;
  background-size: 80% 90% !important;
  background-position: 80% 50% !important;

}

.btn-alibaba {

  background-image: url("https://proxeet.com/img/marketplaces/buyAlibaba.png") !important;
  background-repeat: no-repeat !important;
  background-size: 80% 120% !important;
  background-position: 40% 80% !important;

}



.btn-amazon-tablet {

  font-family: DOCKER !important;
  font-style: normal !important;
  font-size: 1.5rem !important;
  width: 17rem !important;
  height: 4rem !important;
  background: #152F4E !important;
  background-position: center !important;
  border-radius: 30px !important;
  background-image: url("https://proxeet.com/img/marketplaces/buy-on-amazon-button.png") !important;
  background-repeat: no-repeat !important;
  background-size: 10rem 3rem !important;
  border: 0 !important;

}


.btn-amazon-mob {

  font-family: DOCKER !important;
  font-style: normal !important;
  font-size: 1.5rem !important;
  width: 17rem !important;
  height: 4rem !important;
  background: #152F4E !important;
  background-position: center !important;
  border-radius: 30px !important;
  background-image: url("https://proxeet.com/img/marketplaces/buy-on-amazon-button.png") !important;
  background-repeat: no-repeat !important;
  background-size: 10rem 3rem !important;
  border: 0 !important;

}


h2 {
  font-family: 'DOCKER';
  font-style: normal;

  font-size: 36px;


  color: #152F4E;
}

/* --------------------------- */

.container {
  position: relative;
}
video {
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
}
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

/* --------------------------- */

input {
  font-family: 'Open Sans' !important;
  color: #00477A !important;
  font-size: 20px !important;

}

input:focus {
  font-family: 'Open Sans' !important;
  color: #00477A !important;
  font-size: 20px !important;
  /* border: 2px solid #FF7E33 !important; */
  outline: none !important;
  box-shadow: 0 0 0 #FF7E33 !important;
  

}

input:invalid {
  
  
  border: 2px solid red;
  
}

/*
input:active {
  font-family: 'Open Sans' !important;
  color: #00477A !important;
  font-size: 20px !important;
  border: 2px solid #FF7E33 !important;
  outline: none !important;
  box-shadow: 0 0 0 #FF7E33 !important;
  

}
*/

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.rot{
animation: spin 7s linear 0s infinite;
}


@import url('https://fonts.cdnfonts.com/css/helvetica-255');

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}


p,
a {

  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height */

  color: #00477A;



}

.low{

  text-transform: lowercase !important; 

}

.breadcrumb-item + .breadcrumb-item::before {
        
        color: #152F4E;
        font-size: 13px; 
        content: '→' !important;
     }
   
     .br-0 {

      border-radius: 0px !important;
    }

    .br-10 {

      border-radius: 10px !important;
    }

    .br-15 {

      border-radius: 15px !important;
    }
    .br-20 {

      border-radius: 20px !important;
    }
    
    .br-30 {

      border-radius: 30px !important;
    }

    .br-50 {

      border-radius: 50px !important;
    }

    .br-50-percent {

      border-radius: 50% !important;
    }

    .b-dev-1-r {

      border: 1px solid red !important;
    }

    .proxeet-crab-on-card {

      position: absolute !important;
      z-index: 992 !important;

    }